
import { Component, Mixins, Emit } from 'vue-property-decorator';
import Singlepages from '@/mixins/SinglepagesEn';
import PageHeader from '@/components/page/PageHeader.vue';

@Component({
  components: {
    PageHeader,
  },
})
export default class Specialoffer extends Mixins(Singlepages) {
  private name = 'specialoffer';
}
