import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
// @ is an alias to /src
import axios, {AxiosResponse, AxiosError} from 'axios';

@Component
export default class SidenaviNewentries extends Vue {
  private　name = 'sidenavinewentries';
  private list: PostData[] = [];
  @Prop()
  private label!: string;
  @Emit()
  private setList(list: PostData[]) {
    this.list = list;
  }
  @Emit()
  private async getNewEntries(api: string) {
    const result = await axios({
      method: 'get',
      url: api,
      headers: { Authorization: 'Bearer ' + this.$store.getters['auth/token']},
    })
    .then( (response: AxiosResponse<ApiPost>) => {
      this.setList(response.data.datas);
      return true;
    })
    .catch( (e: AxiosError) => {
      console.log(e);
      return false;
    });
    return result;
  }
  @Emit()
  private linkUrl(id: string): string {
    const path: string[] = this.$route.fullPath.split('/');
    path[path.length - 1] = id;
    return path.join('/');
  }
}
