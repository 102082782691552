import { Component, Mixins, Emit } from 'vue-property-decorator';
import ViewsHasSidenaviEn from '@/mixins/ViewsHasSidenaviEn';
import SidenaviNewentries from '@/components/navi/sidenavi/SidenaviNewentries/SidenaviNewentries.vue';
import store from '@/store';

@Component({
  components: {
    SidenaviNewentries,
  },
})
export default class SinglepagesJa extends Mixins(ViewsHasSidenaviEn) {
  get loadedPost(): boolean {
    return this.$store.getters[`single/loaded`];
  }
  public async mounted() {
    const refs: any = this.$refs;
    const $route: any = this.$route;
    if (refs.sidenaviNewsList) {
      await refs.sidenaviNewsList.
        getNewEntries(`/api/wp-json/31corp/v1/posts?cat=${$route.meta.category}&length=5`);
    }
    store.commit('loaded', true);
  }
}
