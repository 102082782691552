
import { Component, Mixins } from 'vue-property-decorator';
import SinglepageContainer from '@/mixins/SinglepageContainer';
import store from '@/store';
import VueRouter from 'vue-router';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave',
]);

const breadcrumbPath: BreadcrumbPath[] = [
  {
    label: 'Home',
    url: '/en/',
  },
  {
    label: 'SPECIAL OFFER ',
    url: '/en/specialoffer/',
  },
];

@Component
export default class SpecialofferContainer extends Mixins(SinglepageContainer) {
  private name = 'SpecialofferContainer';
  private loaded = true;

  private async beforeRouteEnter(to: any, from: VueRouter, next: any) {
    store.commit(`single/resetData`);
    const result: any = await store.dispatch(`single/getEntry`, {
      category: to.meta.category,
      id: to.params.id,
    });
    if (typeof result.data.datas[0] !== 'undefined') {
      const newBreadCrumbPath: BreadcrumbPath[] = [...breadcrumbPath];
      newBreadCrumbPath.push({
        label: result.data.datas[0].title,
        url: to.fullPath,
      });
      store.commit('breadcrumb/setPath', newBreadCrumbPath);
      next();
    } else {
      next({ path: '/en/', replace: true});
    }
  }

  private async beforeRouteUpdate(to: any, from: VueRouter, next: any) {
    this.loaded = false;
    const result: any = await store.dispatch(`single/getEntry`, {
      category: to.meta.category,
      id: to.params.id,
    });
    if (typeof result.data.datas[0] !== 'undefined') {
      const newBreadCrumbPath: BreadcrumbPath[] = [...breadcrumbPath];
      newBreadCrumbPath.push({
        label: result.data.datas[0].title,
        url: to.fullPath,
      });
      store.commit('breadcrumb/setPath', newBreadCrumbPath);
    } else {
      next({ path: '/en/', replace: true});
    }
    this.loaded = true;
    next();
  }

  private mounted() {
    this.campaignAgreeBoxInit();
    this.gwcouponInit();
  }
}
